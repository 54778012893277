import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_GARANTIAS);

class Usuarios {
	obtener_partes(options) {
		return http.get(utils.url_options('/usuarios',options));
	}

	obtener_parte(id) {
		return http.get(`/usuarios/${id}`);
	}

	obtener_usuarios_todos() {
		return http.get('/usuarios/all');
	}

	crear_parte(payload) {
		return http.post('/usuarios', payload);
	}

	editar_parte(id, payload) {
		return http.put(`/usuarios/${id}`, payload);
	}

	eliminar_contacto(usuario, contacto) {
		return http.delete(`/usuarios/${usuario}/contacto/${contacto}`);
	}

	buscar_usuario(usuario) {
		return http.get(`/usuarios/buscar/${usuario}`,{spinner: false});
	}
}

export default new Usuarios();